const userAction = {
  navbar: [],
  footer: [],
  home: [],
  project: [],
  blog: [],
  about: [],
};

export default userAction;
